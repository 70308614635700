<template>
  <div class="tender_detail">
    <div class="main">
      <el-image
        class="banner"
        :src="require('@as/serve/tender/banner.png')"
      ></el-image>
      <div class="total_info">
        <el-image
          class="total_icon"
          :src="require('@as/serve/tender/total_icon.png')"
        ></el-image>
        <div class="item">
          <div class="label">累计发布：资格预审公关</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">招标公告</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">中标候选人公示</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">中标结果公示</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">更正公关公示</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">总</div>
          <div class="value">60933</div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          债券虚假陈述诉讼纠纷解决的“求同存异”之道——兼评《全国法院审理债券纠纷案件座谈会纪要》
        </div>
        <div class="subtitle">
          <div class="item">发布时间：2020-10-09</div>
          <div class="item">浏览量：3456</div>
        </div>
        <div class="info_contaienr">
          <div class="info_item">
            <div class="row">
              <div class="label">截止时间</div>
              <div class="value">2021-05-01</div>
            </div>
            <div class="row">
              <div class="label">开标时间</div>
              <div class="value">2021-07-01</div>
            </div>
          </div>
          <div class="info_item">
            <div class="row">
              <div class="label">招标地区</div>
              <div class="value">河北省</div>
            </div>
            <div class="row">
              <div class="label">公告类型</div>
              <div class="value">招标公告</div>
            </div>
          </div>
        </div>
        <div class="detail">
          一、采购人：聊城市东昌府区中等职业教育学校
          地址：山东省聊城市柳园北路65号(聊城市东昌府区中等职业教育学校)联系方式：06358323103(聊城市东昌府区中等职业教育学校)采购代理机构：山东正信招标有限责任公司
          地址：山东省聊城市开发区县（区）东昌路159号联系方式：16636356325二、采购项目名称：聊城市东昌府区中等职业教育学校现代职业教育新型人才培养实训基地校级数据中心及综合布线项目采购项目编号（采购计划编号）：SDGP371502202002000123
          <p>
            温馨提示：本招标项目仅供正式会员查阅，您的权限不能浏览详细信息,请点击注册/登录，或联系工作人员办理会员入网事宜，成为正式会员后方可获取详细的招标公告、报名表格、项目附件及部分项目招标文件等。
            联系人： 王越 手机：13681224795 (微信同号) 电话：010-59435680
            传真：010-59435680 邮箱：wangyue@zbytb.com QQ：3463529292
          </p>
        </div>
        <div class="page_change">
          <div class="row">
            <div class="label">下一篇:</div>
            <div class="value">
              晋城市规划和自然资源局规划条件附图(正式图)单一来源采购公告
            </div>
          </div>
          <div class="row">
            <div class="label">上一篇:</div>
            <div class="value">
              重点海湾水质水动力调查及污染物来源分析项目竞争性磋商公告
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="float_container">
      <div class="first">
        <el-image
          class="first_icon"
          @click="collect = !collect"
          :src="
            collect
              ? require('@as/serve/tender/collect_icon_active.png')
              : require('@as/serve/tender/icon_1.png')
          "
        >
        </el-image>
        <div class="badge">5</div>
      </div>
      <div class="group">
        <div class="title">分享</div>
        <el-image
          class="icon"
          :src="require('@as/serve/tender/icon_2.png')"
        ></el-image>
        <el-image
          class="icon"
          :src="require('@as/serve/tender/icon_3.png')"
        ></el-image>
        <el-image
          class="icon"
          :src="require('@as/serve/tender/icon_4.png')"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collect: false,
    }
  },
}
</script>
<style lang="less" scoped>
.tender_detail {
  position: relative;
  padding-bottom: 51px;
  background: #f5f7f8;

  .main {
    margin: 0 auto;
    width: 1200px;
    .banner {
      margin-top: 20px;
      width: 1200px;
      height: 120px;
    }
    .total_info {
      margin-top: 20px;
      padding: 13px 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf1f5;
      border: 1px solid #1c99b0;
      border-radius: 3px;
      .total_icon {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
      }
      .item {
        margin-right: 60px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        .label {
          color: #999999;
        }
        .value {
          margin-left: 5px;
          color: #1c99b0;
        }
      }
    }
    .content {
      margin-top: 20px;
      padding: 50px 0 26px;
      background: #ffffff;
      border: 1px solid #e5e8ed;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      opacity: 0.98;
      border-radius: 2px;
      .title {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        text-align: center;
      }
      .subtitle {
        margin-top: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        .item:first-child {
          margin-right: 40px;
        }
      }
      .info_contaienr {
        margin: 26px 40px 0;
        display: flex;
        border: 1px solid #dedede;
        .info_item {
          flex: 1;
          .row {
            display: flex;
            align-items: center;
            &:first-child {
              border-bottom: 1px solid #dedede;
            }
            .label {
              padding: 12px 86px 12px 40px;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #999999;
              background: #e6eef2;
            }
            .value {
              flex: 1;
              padding: 12px 86px 12px 50px;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #333333;
              background: #f5f7f8;
            }
          }
        }
      }
      .detail {
        margin: 50px 40px 0;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
      }
      .page_change {
        margin-top: 46px;
        padding: 22px 40px 0;
        border-top: 1px solid #eeeeee;
        .row {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          cursor: pointer;
          &:first-child {
            margin-bottom: 20px;
          }
          .label {
            margin-right: 10px;
            color: #1c9bb2;
          }
          .value {
            color: #333333;
            &:hover {
              color: #45a2b3;
            }
          }
        }
      }
    }
  }
  .float_container {
    position: absolute;
    top: 164px;
    left: 280px;
    .first {
      position: relative;
      padding: 12px;
      width: 43px;
      height: 43px;
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      border-radius: 50%;
      box-sizing: border-box;
      .first_icon {
        width: 18px;
        height: 17px;
        cursor: pointer;
      }
      .badge {
        position: absolute;
        bottom: 24px;
        right: 0;
        width: 18px;
        height: 20px;
        line-height: 14px;
        font-size: 11px;
        text-align: center;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
        background: center/100% 100% url('~@as/serve/tender/icon_5.png')
          no-repeat;
      }
    }
    .group {
      margin-top: 18px;
      padding: 16px 10px;
      border-radius: 21px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      .title {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .icon {
        margin-top: 15px;
        width: 24px;
        height: 24px;
        display: block;
        box-sizing: content-box;
        cursor: pointer;
        &:not(:last-child) {
          padding-bottom: 17px;
          border-bottom: 1px solid #dedede;
        }
      }
    }
  }
}
</style>